<template>
  <div>
    <img v-if="!showNewComment && loading" class="w-1/2 md:w-1/6 lg:w-1/5 mx-auto" :src="
      theme === 'dark'
        ? '/static/loading_darkmode.gif'
        : '/static/loading_lightmode.gif'
    " alt="loading-gif" style="z-index: 50" />

    <div :style="showNewComment ? `height: ${height} ; overflow-y: auto` : ''" class="px-5 relative"
      :ref="post.id + '-commentBox'">
      <img v-if="showNewComment && loading" class="w-1/2 md:w-1/6 lg:w-1/5" :src="
        theme === 'dark'
          ? '/static/loading_darkmode.gif'
          : '/static/loading_lightmode.gif'
      " alt="loading-gif" style="
          z-index: 50;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
        " />

      <UserComment v-if="!loading" :loading="loading" :previewComment="previewComment" :comments="comments" :post="post"
        @deleteComment="deleteComment" @commentDeleted="commentDeleted" @updateComment="updateComment">
      </UserComment>
    </div>

    <t-input-group v-if="showNewComment" class="px-5 relative">
      <t-input class="pr-20" :name="post.id + '-commentText'" v-model="commentText" :id="post.id + '-commentText'"
        :placeholder="$i18n.t('add_comment')" v-on:keyup.enter="!isLoading ? postComment() : null"
        :ref="post.id + '-commentText'">
      </t-input>
      <Suggestions :text.sync="commentText" :inputId="post.id + '-commentText'" :taggedUsers.sync="ids_tagged_users" />

      <p v-if="!isLoading" @click="commentText === '' ? null : postComment()" style="
          position: absolute;
          right: 8%;
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
        " class="select-none" :class="commentText === '' ? 'text-gray-500' : 'text-primary-600'">
        {{ $i18n.t("send") }}
      </p>

      <t-loader v-if="isLoading" extraClass="border-orange-primary" style="position: absolute; right: 5%; top: 20%" />
    </t-input-group>

    <t-modal :header="$i18n.t('are_you_sure')" :value="deleteConfirmation" @closed="onClosed" variant="md">
      <div class="p-3">
        <p class="mb-1 text-black dark:text-white">
          {{ $i18n.t("comment_delete_confirmation") }}
        </p>
      </div>

      <template v-slot:footer>
        <div class="flex justify-end">
          <t-button type="button" variant="gray" @click="onClosed" class="mr-2">
            {{ $i18n.t("no") }}
          </t-button>
          <t-button type="button" @click="deleteComment()">{{
              $i18n.t("delete")
          }}</t-button>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import Suggestions from "@/components/user/Suggestions.vue"
import Replies from "./Replies.vue"
import UserComment from "./UserComment.vue"
export default {
  components: {
    ProfilePicture,
    Suggestions,
    Replies,
    UserComment
  },
  props: {
    areReplies: {
      type: Boolean,
      default: false
    },
    post: {
      type: Object,
      default: () => { }
    },
    showNewComment: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "100%"
    },
    previewComment: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    OAuthPreferences() {
      return this.$store.getters["auth/getOAuthPreferences"]
    },
    theme() {
      return this.$store.getters["getTheme"]
    },
  },
  data() {
    return {
      commentText: "",
      id_comment_reply: null,
      ids_tagged_users: [],
      loading: true,
      showReplies: [],
      cascadeReplies: false,
      deleteConfirmation: false,
      currentComment: {},
      isLoading: false,
      comments: []
    }
  },
  methods: {
    openProfile(user) {
      if (this.loggedUser && this.loggedUser.id === user.id) {
        this.$router.push("/profile")
      } else {
        this.$router.push(`/user/${user.username}`)
      }
    },
    updateComment(comment) {
      let index = this.comments.findIndex((x) => x.id === comment.id)
      if (index !== -1) {
        this.comments[index] = comment
      }
    },
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    deleteComment(comment) {
      if (!this.deleteConfirmation) {
        this.currentComment = comment
        this.deleteConfirmation = true
        return { ok: false }
      }
      this.isLoading = true
      this.$store
        .dispatch("global/changeStatus", {
          payload: { id: this.currentComment.id, status: 2 },
          route: "/post_comment"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification(
              "comment-delete",
              response.code,
              this.$snotify,
              this.$i18n.locale
            )
          })
          if (response.code === 200) {
            let index = this.comments.findIndex(
              (x) => x.id === response.data.id
            )
            if (index !== -1) {
              this.comments.splice(index, 1)
            }

            this.$store.dispatch("post/deleteComment", this.currentComment)
            this.deleteConfirmation = false
            this.$emit("commentDeleted", this.currentComment)
            this.isLoading = false
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
            return { ok: false }
          })
        })
    },
    onClosed() {
      this.deleteConfirmation = false
    },
    commentDeleted(comment) {
      this.$emit("commentDeleted", comment)
    },
    toggleReplies(commentId) {
      if (this.showReplies.includes(commentId)) {
        this.showReplies = this.showReplies.filter((x) => x !== commentId)
      } else {
        this.showReplies.push(commentId)
      }
    },
    respond(comment) {
      if (!this.showNewComment) {
        this.$emit("updateCommentText", comment)
      } else {
        this.commentText = `@${comment.user.username} `
        this.id_comment_reply = comment.id
        this.ids_tagged_users.push(`@${comment.user.username}`)
        this.$refs[this.post.id + "-commentText"].focus()
      }
    },
    addComment(comment, type) {
      if (type !== 'reply') {
        this.comments.push(comment)
        if (this.showNewComment) {
          this.$nextTick(() => {
            this.$refs[this.post.id + "-commentBox"].scrollTo(
              0,
              this.$refs[this.post.id + "-commentBox"].scrollHeight
            )
          })
        } else {
          this.$emit("scrollToBottom")
        }

        return
      }
      if (type === 'reply') {
        let index = this.comments.findIndex(
          (x) => x.id === comment.id_comment_reply
        )
        if (index !== -1) {
          this.comments[index].replies.push(comment)
        }
        this.comments.push(comment)
      }


      if (this.showNewComment) {
        this.$nextTick(() => {
          this.$refs[this.post.id + "-commentBox"].scrollTo(
            0,
            this.$refs[this.post.id + "-commentBox"].scrollHeight
          )
        })
      } else {
        this.$emit("scrollToBottom")
      }
    },
    postComment() {
      if (this.commentText === "")
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "Comment cannot be empty"
            : "El comentario no puede estar vacío",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )

      if (this.commentText.length > 1000)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You have exceeded 1000 characteres limit"
            : "Has excedido el límite de 1000 caracteres",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )

      this.isLoading = true
      let payload = {
        id_user: this.loggedUser.id,
        comment: this.commentText,
        id_post: this.post.id
      }
      if (this.id_comment_reply)
        payload.id_comment_reply = this.id_comment_reply
      if (this.ids_tagged_users.length > 0)
        payload.ids_tagged_users = this.ids_tagged_users
      this.$store
        .dispatch("post/addComment", {
          payload: {
            ...payload,
            bearer_token:
              this.OAuthPreferences &&
                this.OAuthPreferences.accessToken &&
                this.OAuthPreferences.likes_comments
                ? this.OAuthPreferences.accessToken
                : null
          },
          post: this.post
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            this.addComment(response.data, 'comment')
            this.commentText = ""
          }
          this.isLoading = false
        })
        .catch((error) => {
          console.log("post comment error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
          this.isLoading = false
        })
    },
    likeComment(comment) {
      let commentx = comment
      commentx.liked = commentx.liked === 1 ? 0 : 1
      if (commentx.liked === 1) {
        commentx.total_likes++
      } else {
        commentx.total_likes--
      }

      this.updateComment(commentx)
      this.$store
        .dispatch("global/create", {
          payload: {
            id_user: this.loggedUser.id,
            type_child: "comment",
            id_child: commentx.id
          },
          route: "/like/save",
          module: null
        })
        .then(() => { })
        .catch((error) => {
          console.log("comment like error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    },
    loadComments() {
      this.loading = true

      if (this.previewComment) {
        return this.$store
          .dispatch("global/getItems", {
            route: "/post_comment/all_by_post",
            noPaginate: true,
            params: {
              id_post: this.post.id,
              order_value: "asc",
              active: 1
            }
          })
          .then((response) => {
            if (this.cascadeReplies) {
              this.comments = response.data.filter((x) => !x.id_comment_reply)
            } else {
              this.comments = response.data
            }

            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            notify().then(({ notification }) => {
              notification("error", error.code, this.$snotify, this.$i18n.locale)
            })
          })
      }

      this.$store
        .dispatch("global/getItems", {
          route: "/post_comment/all_by_post",
          noPaginate: true,
          params: {
            id_post: this.post.id,
            order_value: "asc",
            active: 1
          }
        })
        .then((response) => {
          console.log(response.data);
          if (this.cascadeReplies) {
            this.comments = response.data.filter((x) => !x.id_comment_reply)
          } else {
            this.comments = response.data
          }

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify, this.$i18n.locale)
          })
        })
    }
  },
  created() {
    if (this.post.id) {
      this.loadComments()
    }
  },
  watch: {
    post() {
      if (this.post.id) this.loadComments()
    },
    commentText() {
      if (this.commentText === "") {
        if (this.id_comment_reply) {
          // If user resets input after having selected "Respond"
          this.id_comment_reply = null
          this.ids_tagged_users = []
        }
      }
    }
  }
}
</script>
<style>

</style>