<template>
  <div>
    <div class="px-5" v-if="element.type === 3">
      <p class="text-black dark:text-white text-md sm:text-md" v-html="$detectHashtagsAndUsernames(element.text)"
        @click="handleProcessedTextClick"></p>
    </div>

    <div class="px-5" v-else-if="[1, 2, 5].includes(element.type)">
      <p class="my-0 py-0">
        <span>
          <Username :user="element.user" />
        </span>
        <span class="text-black dark:text-white text-sm sm:text-md" v-html="$detectHashtagsAndUsernames(element.text)"
          @click="handleProcessedTextClick"></span>
      </p>
    </div>
  </div>
</template>

<script>
import Username from '@/components/user/Username.vue'

export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    loggedUserID: {
      type: Number
    },
    sizePx: Number
  },
  components: {
    Username
  },
  methods: {
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
  }
}
</script>
