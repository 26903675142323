var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},_vm._l((_vm.element.questions.slice()),function(pollElement){return _c('div',{key:pollElement.question.id},[_c('p',{staticClass:"text-black dark:text-white text-sm md:text-md my-2 font-bold"},[_vm._v(" "+_vm._s(pollElement.question.question)+" ")]),_vm._l((pollElement.answers),function(answer){return _c('div',{key:answer.answer.id,staticClass:"mb-3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"bg-transparent rounded-lg relative flex justify-center border-primary-1100 py-3",class:pollElement.answered_by_you || _vm.element.ended
              ? 'w-11/12'
              : 'w-full',staticStyle:{"border-width":"1px"},style:(!_vm.element.ended && _vm.loggedUserID ? 'cursor:pointer' : ''),on:{"click":function($event){!_vm.element.ended && _vm.loggedUserID
    ? _vm.answerPoll(
      _vm.element,
      pollElement.question.id,
      answer.answer.id,
      pollElement.answered_by_you
    )
    : {}}}},[(pollElement.answered_by_you || _vm.element.ended)?_c('div',{staticClass:"absolute left-0 top-0 bg-primary-1100 rounded-lg h-full border-primary-1100",staticStyle:{"z-index":"5"},style:('width:' + answer.percentage + '%;')}):_vm._e(),_c('p',{staticClass:"mx-auto text-center my-auto text-black dark:text-white text-sm md:text-md",staticStyle:{"z-index":"10"}},[(answer.selected_by_you)?_c('img',{staticClass:"inline-block mx-auto dark:filter invert",staticStyle:{"cursor":"pointer","width":"20px"},attrs:{"src":"/static/icons/check.svg","alt":"Check icon"}}):_vm._e(),_vm._v(" "+_vm._s(answer.answer.answer)+" ")])]),(pollElement.answered_by_you || _vm.element.ended)?_c('p',{staticClass:"text-center text-black dark:text-white text-sm md:text-md my-2 ml-1",class:pollElement.answered_by_you || _vm.element.ended ? 'w-1/12' : ''},[_vm._v(" "+_vm._s(answer.percentage)+"% ")]):_vm._e()])])}),_c('p',{staticClass:"text-center text-black dark:text-white text-sm md:text-md my-2 ml-1"},[_vm._v(" "+_vm._s(pollElement.total_answers_for_question)+" "+_vm._s(_vm.$i18n.t("votes"))+" "+_vm._s(_vm.element.ended ? " - " + _vm.$i18n.t("poll_ended") : "")+" ")])],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }