<template>
    <div>
        <div class="w-full my-1" v-for="(comment, idx) in comments" :key="'comment-' + comment.id">
            <template v-if="(idx < 3 && previewComment) || !previewComment">
                <div class="flex justify-between items-center">
                    <div class="w-6 h-6 mr-2 self-start" @click="openProfile(comment.user)" style="cursor: pointer">
                        <profile-picture :data="comment.user" :sizePx="64" />
                    </div>

                    <div class="w-10/12 flex">
                        <div style="width: 100%; inline-size: 100%;
        overflow-wrap: break-word;">
                            <p style="display: inline-flex; text-wrap: break-word; width: auto, align-items: start;">
                                <span style="width: auto; display: inline-flex; ">
                                    <span @click="openProfile(comment.user)"
                                        class="mr-1 text-black dark:text-white text-sm sm:text-md"
                                        style="cursor: pointer; font-weight: 700;">{{
                                                comment.user.username
                                        }}
                                    </span>

                                    <span class="mr-1" style="width: 16px;" v-if="comment.user.verified == 1">
                                        <img src="/static/user_verified.png" style="
                              {
                              display: flex;
                              width: 100%;
                            }
                          " />
                                        <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
                                    </span>
                                </span>

                            </p>
                            <span class="text-black dark:text-white text-sm sm:text-md"
                                v-html="$detectHashtagsAndUsernames(formatComment(comment.comment))"></span>

                            <audio v-if="
                                comment.url_voice &&
                                new Date() < new Date(comment.available_until + 'Z')
                            " controls>
                                <source :src="comment.url_voice" type="audio/mp4" />
                            </audio>

                            <p class="text-black dark:text-white py-1 text-sm" v-if="
                                new Date(comment.available_until + 'Z') < new Date()
                            ">
                                <strong>{{ $i18n.t("voice_msg_expired") }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class=" w-1/12 self-start">
                        <div v-if="loggedUser" class="flex flex-col sm:flex-row justify-start items-center">
                            <img v-if="comment.liked === 1" src="/static/icons/likeFilled.svg" alt="Jevi button"
                                class="mx-auto sm:my-0 cursor-pointer primary-color-svg" width="16px"
                                @click="likeComment(comment)" />

                            <img v-else src="/static/icons/like.svg" alt="Jevi button"
                                class="mx-auto sm:my-0 dark:filter invert" style="cursor: pointer; width: 16px"
                                @click="likeComment(comment)" />

                            <img v-if="comment.id_user === loggedUser.id" src="/static/icons/delete.svg"
                                alt="Delete button" class="ml-1 sm:my-0 dark:filter invert"
                                style="cursor: pointer; width: 16px" @click="deleteComment(comment)" />
                        </div>
                    </div>
                </div>

                <Replies :previewComment="!previewComment" v-if="!loading" @likeComment="likeComment" :comment="comment"
                    @addComment="addComment" @commentDeleted="commentDeleted" :post="post" />
            </template>
        </div>
    </div>
</template>

<script>
import ProfilePicture from "../user/ProfilePicture.vue"
import Replies from "./Replies.vue"

export default {
    components: { ProfilePicture, Replies },
    emits: ['commentDeleted', 'handleProcessedTextClick', 'deleteComment'],
    props: {
        comments: {
            type: Array,
            required: true,
        },
        post: {
            type: Object,
            required: true,
        },
        previewComment: {
            type: Boolean,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        }
    },
    computed: {
        loggedUser() {
            return this.$store.getters["auth/getUser"]
        },
    },
    emits: ['addComment', 'commentDeleted'],
    methods: {
        addComment(reply, type) {
            this.$emit('addComment', reply, type)
        },
        formatComment(comment) {
            if (comment.length > 35 && this.previewComment) return comment.substring(0, 35) + '...'
            return comment
        },
        openProfile(user) {
            if (this.loggedUser && this.loggedUser.id === user.id) {
                this.$router.push("/profile")
            } else {
                this.$router.push(`/user/${user.username}`)
            }
        },
        commentDeleted(comment) {
            this.$emit("commentDeleted", comment)
        },
        deleteComment(comment, type) {
            this.$emit("deleteComment", comment, type)
        },
        likeComment(comment) {
            console.log(comment);
            let commentx = comment
            commentx.liked = commentx.liked === 1 ? 0 : 1
            if (commentx.liked === 1) {
                commentx.total_likes++
            } else {
                commentx.total_likes--
            }

            this.$emit('updateComment', commentx)
            this.$store
                .dispatch("global/create", {
                    payload: {
                        id_user: this.loggedUser.id,
                        type_child: "comment",
                        id_child: commentx.id
                    },
                    route: "/like/save",
                    module: null
                })
                .then(() => { })
                .catch((error) => {
                    console.log("comment like error:", error)
                    notify().then(({ notification }) => {
                        notification("error", error.code, this.$snotify, this.$i18n.locale)
                    })
                })
        },
    }
}
</script>