<template>
  <div v-show="show" class="suggestions-container absolute py-1 rounded-md">
    <ul id="suggestions-results">
      <p class="text-sm text-center" v-if="!loading && suggestions.length === 0">
        {{ $i18n.t("no_results") }}
      </p>
      <li class="flex items-center" v-for="suggestion in suggestions" :key="suggestion.id"
        @click="selectSuggestion(suggestion)">
        <img class="flex w-8 h-8 rounded-full mr-2" :src="image(suggestion)" />
        <p class="text-sm" style="display: flex; align-items: center;">
          {{ suggestion.username }}
          <span class="ml-1" v-if="suggestion.verified == 1">
            <img src="/static/user_verified.png" style="{ display: flex; height: 16px; width: 16px; }" />
            <!--<font-awesome-icon class="mt-2 mb-0 my-0" icon="check-circle" style="color: #6a71ec; font-size: 16px" />-->
          </span>
        </p>
      </li>
      <div class="flex justify-center">
        <t-loader v-if="loading" extraClass="border-gray-500 border-orange-primary" />
      </div>
    </ul>
  </div>
</template>

<script>
import { debounce } from "lodash"
export default {
  components: {},
  props: {
    text: {
      type: String,
      default: ""
    },
    inputId: {
      type: String,
      default: null
    },
    taggedUsers: {
      type: Array,
      default: () => []
    },
    preferredDirection: {
      type: String,
      default: "top"
    }
  },
  data() {
    return {
      show: false,
      query: "",
      selectedSuggestion: null,
      selectedUsernames: [],
      loading: false,
      direction: ""
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    },
    suggestions() {
      return this.$store.getters["user/getAllSuggestedUsernames"].filter(
        (x) => !this.selectedUsernames.includes(x.username)
      )
    }
  },
  methods: {
    image(element) {
      return element.imgkit_profile_picture
        ? element.imgkit_profile_picture
        : element.profile_picture
    },
    selectSuggestion(suggestion) {
      this.selectedSuggestion = suggestion
      this.selectedUsernames.push(suggestion.username)
      let taggedUsers = [...this.taggedUsers]
      taggedUsers.push(`@${suggestion.username}`)
      this.$emit("update:taggedUsers", taggedUsers)
      let query_text = "@" + this.query

      //  query and showSuggestion flag automatically change on commentText watch
      this.$store.dispatch("user/setSuggestedUsernames", [])
      let text = this.text

      this.$emit(
        "update:text",
        text.replace(query_text, `@${suggestion.username} `)
      )
    },
    loadSuggestionsDebounced() { },
    loadSuggestions() {
      this.loading = true
      this.$socket.emit("find_by_username", {
        id_user: this.loggedUser.id,
        username: this.query
      })
    }
  },
  created() {
    this.loadSuggestionsDebounced = debounce(this.loadSuggestions, 300)
    this.$store.dispatch("user/setSuggestedUsernames", [])
    this.direction = this.preferredDirection

    if (this.taggedUsers.length > 0) {
      for (let user of this.taggedUsers) {
        this.selectedUsernames.push(this.$removeAtSymbol(user))
      }
    }
  },
  watch: {
    text() {
      for (let username of this.selectedUsernames) {
        if (!this.text.includes(username)) {
          let index = this.selectedUsernames.findIndex((x) => x === username)
          if (index !== -1) this.selectedUsernames.splice(index, 1)

          index = this.taggedUsers.findIndex((x) => x === `@${username}`)
          if (index !== -1) {
            let currently_tagged = [...this.taggedUsers]
            currently_tagged.splice(index, 1)
            this.$emit("update:taggedUsers", currently_tagged)
          }
        }
      }

      if (this.text !== "") {
        const response = this.selectedSuggestion
          ? { inputting_username: false }
          : this.$detectUsernameInput(this.text)

        if (response.inputting_username) {
          this.query = response.username
          this.show = true
          this.loadSuggestionsDebounced()
        } else {
          this.selectedSuggestion = null
          this.query = ""
          this.show = false
        }
      } else {
        this.query = ""
        this.show = false
      }
    },
    suggestions() {
      this.loading = false
      this.$nextTick(() => {
        let element = document.getElementById("suggestions-results")
        let element_position
        if (element) {
          let directionSet = false
          element_position = element.getBoundingClientRect()

          if (element_position.top <= 0) {
            this.direction = "top"
            directionSet = true
          }

          if (element_position.bottom > window.innerHeight) {
            this.direction = "bottom"
            directionSet = true
          }

          if (!directionSet) this.direction = this.preferredDirection
        }
      })
    }
  }
}
</script>
