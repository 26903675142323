<template>
    <div>
        <p v-if="(previewComment)">
            <span class="text-xs mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                    comment.created_at | fromNow
            }}</span>

            <span v-if="loggedUser" @click="toggle('input')"
                class="cursor-pointer text-xs mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                        $i18n.t('reply')
                }}</span>
            <span v-if="allLikesText(comment.total_likes).show" @click="openLikesModal(comment.id)"
                class="cursor-pointer text-xs mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                        likesTextCounter(comment.total_likes)
                }}</span>
        </p>

        <p v-if="(allRepliesText(comment.total_replies).show && previewComment || allRepliesText(comment.total_replies).show)"
            @click="toggle('reply')"
            class="cursor-pointer text-sm mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                    allRepliesText(comment.total_replies).messagge
            }}</p>
        <div v-if="showReplies" :ref="'reply' + comment.id"
            style=" max-height: 25vh; overflow-y: auto; overflow-x: hidden;" class="w-full mb-5 px-5">
            <ul class="w-full  StepProgress">
                <li v-for="reply in replies" class="StepProgress-item">
                    <div class=" flex justify-between items-center">
                        <div class="w-6 h-6 mr-2 self-start" @click="openProfile(reply.user)" style="cursor: pointer">
                            <profile-picture :data="reply.user" :sizePx="64" />
                        </div>
                        <div class="w-10/12 flex">
                            <div style="width: 100%; inline-size: 100%;
            overflow-wrap: break-word;">
                                <p
                                    style="display: inline-flex; text-wrap: break-word; width: auto, align-items: start;">
                                    <span style="width: auto; display: inline-flex; ">
                                        <span @click="openProfile(reply.user)"
                                            class="mr-1 text-black dark:text-white text-sm sm:text-md"
                                            style="cursor: pointer; font-weight: 700;">{{
                                                    reply.user.username
                                            }}
                                        </span>

                                        <span class="mr-1" style="width: 16px;" v-if="reply.user.verified == 1">
                                            <img src="/static/user_verified.png" style="
                                  {
                                  display: flex;
                                  width: 100%;
                                }
                              " />

                                        </span>
                                    </span>

                                </p>
                                <span class="text-black dark:text-white text-sm sm:text-md"
                                    v-html="$detectHashtagsAndUsernames(reply.comment)"></span>

                                <audio v-if="
                                    reply.url_voice &&
                                    new Date() < new Date(reply.available_until + 'Z')
                                " controls>
                                    <source :src="reply.url_voice" type="audio/mp4" />
                                </audio>

                                <p class="text-black dark:text-white py-1 text-sm" v-if="
                                    new Date(reply.available_until + 'Z') < new Date()
                                ">
                                    <strong>{{ $i18n.t("voice_msg_expired") }}</strong>
                                </p>
                            </div>
                        </div>

                        <div class=" w-1/12 self-start">

                            <div v-if="loggedUser" class="flex flex-col sm:flex-row justify-start items-center">
                                <img v-if="reply.liked === 1" src="/static/icons/likeFilled.svg" alt="Jevi button"
                                    class="mx-auto sm:my-0 cursor-pointer primary-color-svg" width="16px"
                                    @click="likeComment(reply)" />

                                <img v-else src="/static/icons/like.svg" alt="Jevi button"
                                    class="mx-auto sm:my-0 dark:filter invert" style="cursor: pointer; width: 16px"
                                    @click="likeComment(reply)" />

                                <img v-if="reply.id_user === loggedUser.id" src="/static/icons/delete.svg"
                                    alt="Delete button" class="ml-1 sm:my-0 dark:filter invert"
                                    style="cursor: pointer; width: 16px" @click="deleteComment(reply)" />
                            </div>
                        </div>
                    </div>
                    <p>
                        <span class="text-sm mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                                reply.created_at | fromNow
                        }}</span>

                        <span @click="openLikesModal(reply.id)" v-if="allLikesText(reply.total_likes).show"
                            class="cursor-pointer text-sm mx-4 my-1 font-bold text-black-500 dark:text-gray-400">{{
                                    likesTextCounter(reply.total_likes)
                            }}</span>
                    </p>
                </li>

            </ul>
        </div>

        <div :ref="'input' + comment.id" v-if="showReplyInput" class="w-96 StepProgress-item">
            <t-input-group :feedback="
                errors.first(post.id + '-pm-replyText')
                    ? errors.first(post.id + '-pm-replyText')
                    : ''
            " :variant="errors.first(post.id + '-pm-replyText') ? 'danger' : ''" style="position: relative">
                <!-- <p v-if="!isLoading || showComments" @click="commentText === '' ? null : sendComment(post)"
            style="position: absolute; right: 8%; top: 20%; cursor: pointer" class="select-none"
            :class="commentText === '' ? 'text-gray-500' : 'text-primary-600'">
            {{ $i18n.t("send") }}
          </p> -->
                <t-loader v-if="isLoading" extraClass="border-orange-primary"
                    style="position: absolute; right: 5%; top: 20%" />

                <Suggestions :text.sync="replyText" :inputId="post.id + '-pm-replyText'"
                    :taggedUsers.sync="ids_tagged_users" />
                <t-input class="pr-20 mt-1    " v-validate="'max:1000'" data-vv-validate-on="change"
                    :name="comment.id + '-pm-replyText'" :id="comment.id + '-pm-replyText'" v-model="replyText"
                    :ref="comment.id + '-pm-replyText'" :placeholder="ownComment(comment.user)"
                    v-on:keyup.enter="!isLoading ? postComment(comment.id) : null" />
            </t-input-group>
        </div>
        <t-modal :header="$i18n.t('are_you_sure')" :value="deleteConfirmation" @closed="onClosed" variant="md">
            <div class="p-3">
                <p class="mb-1 text-black dark:text-white">
                    {{ $i18n.t("comment_delete_confirmation") }}
                </p>
            </div>

            <template v-slot:footer>
                <div class="flex justify-end">
                    <t-button type="button" variant="gray" @click="deleteConfirmation = false" class="mr-2">
                        {{ $i18n.t("no") }}
                    </t-button>
                    <t-button type="button" @click="deleteComment()">{{
                            $i18n.t("delete")
                    }}</t-button>
                </div>
            </template>
        </t-modal>
        <LikedByModal :modal="likesModal" type="comment" :postID="commentID" @closeModal="closeLikesModal"
            @openProfile="openProfileFromLikesModal" />

    </div>
</template>
<script>
import Comments from "@/components/post/comments.vue"
import Suggestions from "@/components/user/Suggestions.vue"
import ProfilePicture from "@/components/user/ProfilePicture.vue"
import LikedByModal from "./LikedByModal.vue"
const notify = () => import("@/utils/notify.js")
export default {
    components: {
        Comments,
        Suggestions,
        ProfilePicture,
        LikedByModal
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        previewComment: {
            type: Boolean,
            default: false
        },
        post: {
            type: Object,
            required: true
        }
    },
    computed: {
        loggedUser() {
            return this.$store.getters["auth/getUser"]
        },

    },
    data() {
        return {
            replies: [],
            replyText: '',
            isLoading: false,
            showReplies: false,
            showReplyInput: false,
            ids_tagged_users: [],
            deleteConfirmation: false,
            likesModal: false,
            commentID: 0
        }
    },
    emits: ['addComment', 'likeComment', 'commentDeleted'],
    methods: {
        onClosed() {
            this.deleteConfirmation = false
        },
        replyComment(reply) {
            this.$emit('addComment', reply, 'reply')
        },
        closeLikesModal() {
            this.likesModal = false
            this.commentID = 0
        },
        openLikesModal(postID) {
            this.commentID = postID
            this.likesModal = true
        },
        openProfileFromLikesModal(user) {
            this.likesModal = false
            if (this.loggedUser && this.loggedUser.id === user.id) {
                this.$router.push("/profile")
            } else {
                this.$router.push(`/user/${user.username}`)
            }
        },
        scrollToElement(type) {
            let el
            setTimeout(() => {
                this.$nextTick(() => {
                    el = this.$refs[type + this.comment.id];

                    el.scrollTo(
                        0,
                        el.scrollHeight
                    )

                    if (type === 'input') {
                        this.$refs[this.comment.id + '-pm-replyText'].focus()
                    }
                })


            }, 100);




        },
        toggle(term) {
            if (term === 'input') {
                this.showReplyInput = !this.showReplyInput
                if (this.showReplyInput) return this.scrollToElement(term)
            }
            if (term === 'reply') {
                this.showReplies = !this.showReplies
                if (this.showReplies) return this.scrollToElement(term)
            }
        },
        postComment(commentID) {
            if (this.replyText === "")
                return this.$snotify.error(
                    this.$i18n.locale === "en"
                        ? "Comment cannot be empty"
                        : "El comentario no puede estar vacío",
                    this.$i18n.locale === "en" ? "Error" : "Error",
                    {
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        titleMaxLength: 40,
                        bodyMaxLength: 100,
                        position: "rightBottom"
                    }
                )

            if (this.replyText.length > 1000)
                return this.$snotify.error(
                    this.$i18n.locale === "en"
                        ? "You have exceeded 1000 characteres limit"
                        : "Has excedido el límite de 1000 caracteres",
                    this.$i18n.locale === "en" ? "Error" : "Error",
                    {
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        titleMaxLength: 40,
                        bodyMaxLength: 100,
                        position: "rightBottom"
                    }
                )

            this.isLoading = true
            let payload = {
                id_user: this.loggedUser.id,
                comment: this.replyText,
                id_post: this.post.id,
                id_comment_reply: commentID
            }
            this.$store
                .dispatch("post/addComment", {
                    payload: {
                        ...payload,
                        bearer_token:
                            this.OAuthPreferences &&
                                this.OAuthPreferences.accessToken &&
                                this.OAuthPreferences.likes_comments
                                ? this.OAuthPreferences.accessToken
                                : null
                    },
                    post: this.post
                })
                .then((response) => {
                    if ([200, 201].includes(response.code)) {
                        this.replyComment(response.data, 'reply')
                        this.replyText = ""
                    }
                    this.isLoading = false
                    this.replies.push(response.data)
                    this.showReplyInput = false
                    this.scrollToElement('reply')
                })
                .catch((error) => {
                    console.log("post comment error:", error)
                    notify().then(({ notification }) => {
                        notification("error", error.code, this.$snotify, this.$i18n.locale)
                    })
                    this.isLoading = false
                })
        },
        openProfile(user) {
            if (this.loggedUser && this.loggedUser.id === user.id) {
                this.$router.push("/profile")
            } else {
                this.$router.push(`/user/${user.username}`)
            }
        },

        deleteComment(comment) {
            if (!this.deleteConfirmation) {
                this.currentComment = comment
                this.deleteConfirmation = true
                return { ok: false }
            }
            this.isLoading = true
            this.$store
                .dispatch("global/changeStatus", {
                    payload: { id: this.currentComment.id, status: 2 },
                    route: "/post_comment"
                })
                .then((response) => {
                    notify().then(({ notification }) => {
                        notification(
                            "comment-delete",
                            response.code,
                            this.$snotify,
                            this.$i18n.locale
                        )
                    })
                    if (response.code === 200) {
                        let index = this.replies.findIndex(
                            (x) => x.id === response.data.id
                        )
                        if (index !== -1) {
                            this.replies.splice(index, 1)
                        }

                        this.$store.dispatch("post/deleteComment", this.currentComment)
                        this.deleteConfirmation = false
                        this.$emit("commentDeleted", this.currentComment)
                        this.isLoading = false
                        this.showReplyInput = false
                    }
                })
                .catch((error) => {
                    notify().then(({ notification }) => {
                        notification("error", error.code, this.$snotify, this.$i18n.locale)
                        return { ok: false }
                    })
                })
        },
        likeComment(comment) {
            this.$emit("likeComment", comment)
            comment.liked = comment.liked === 1 ? 0 : 1
        },
        likesTextCounter(totalLikes) {
            if (totalLikes === 1) return ` ${totalLikes} ${this.$i18n.t('like')} `
            return `${totalLikes} ${this.$i18n.t('likes').toLowerCase()} `
        },

        allLikesText(totalLikes) {
            if (totalLikes === 0) return { show: false }
            if (totalLikes === 1) return { show: true, messagge: ` ${totalLikes} ${this.$i18n.t('like')} ` }
            return { show: true, messagge: `${this.$i18n.t('view_all_2')} ${totalLikes} ${this.$i18n.t('likes').toLowerCase()} ` }
        },
        allRepliesText(total_replies) {
            if (this.showReplies) return { show: true, messagge: `${this.$i18n.t('hide_replies')}` }
            if (total_replies === 0) return { show: false }
            if (total_replies === 1) return { show: true, messagge: `${this.$i18n.t('view')} ${total_replies} ${this.$i18n.t('reply_text')} ` }
            return { show: true, messagge: `${this.$i18n.t('view_all_2')} ${total_replies} ${this.$i18n.t('replies').toLowerCase()} ` }
        },
        updateCommentText(comment) {
            this.commentText = `@${comment.user.username} `
            this.id_comment_reply = comment.id
            this.ids_tagged_users.push(`@${comment.user.username}`)
            this.$refs[this.element.id + "-pm-commentText"].focus()
        },
        scrollComments() {
            this.$nextTick(() => {
                this.$refs[this.element.id + "-pm-commentBox"].scrollTo(
                    0,
                    this.$refs[this.element.id + "-pm-commentBox"].scrollHeight
                )
            })
        },
        ownComment(commentUser) {
            if (this.loggedUser && commentUser.id === this.loggedUser.id) return this.$i18n.t('reply_to_yourself')

            return `${this.$i18n.t('reply_to_user')} ${commentUser.username}`
        }
    },
    created() {
        this.replies = this.comment.replies
    }
}
</script>
<style>
.StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
}

.StepProgress::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 1px solid #CCC;
    transform: translateX(-15%);
}

.StepProgress-item:not(:last-child) {
    padding-bottom: 15px;
}


.StepProgress-item {
    position: relative;
    counter-increment: list;
}


.StepProgress-item::before {
    display: inline-block;
    content: '';
    position: absolute;

    left: -30px;
    height: 90%;
    width: 10px;
}

.StepProgress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    left: -35px;
    width: 8px;
    height: 8px;
    border: 1px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
}

.StepProgress-item::before:last-child {
    display: none;
}
</style>