<template>
  <div class="px-5">
    <div v-for="pollElement in element.questions.slice()" :key="pollElement.question.id">
      <p class="text-black dark:text-white text-sm md:text-md my-2 font-bold">
        {{ pollElement.question.question }}
      </p>
      <div v-for="answer in pollElement.answers" :key="answer.answer.id" class="mb-3">
        <div class="flex">
          <div class="bg-transparent rounded-lg relative flex justify-center border-primary-1100 py-3" :class="
            pollElement.answered_by_you || element.ended
              ? 'w-11/12'
              : 'w-full'
          " style="border-width: 1px" :style="!element.ended && loggedUserID ? 'cursor:pointer' : ''" @click="
  !element.ended && loggedUserID
    ? answerPoll(
      element,
      pollElement.question.id,
      answer.answer.id,
      pollElement.answered_by_you
    )
    : {}
">
            <div v-if="pollElement.answered_by_you || element.ended"
              class="absolute left-0 top-0 bg-primary-1100 rounded-lg h-full border-primary-1100" style="z-index: 5"
              :style="'width:' + answer.percentage + '%;'"></div>
            <p class="mx-auto text-center my-auto text-black dark:text-white text-sm md:text-md" style="z-index: 10">
              <img v-if="answer.selected_by_you" src="/static/icons/check.svg" alt="Check icon"
                class="inline-block mx-auto dark:filter invert" style="cursor: pointer; width: 20px" />

              {{ answer.answer.answer }}
            </p>
          </div>
          <p v-if="pollElement.answered_by_you || element.ended"
            class="text-center text-black dark:text-white text-sm md:text-md my-2 ml-1" :class="
              pollElement.answered_by_you || element.ended ? 'w-1/12' : ''
            ">
            {{ answer.percentage }}%
          </p>
        </div>
      </div>
      <p class="text-center text-black dark:text-white text-sm md:text-md my-2 ml-1">
        {{ pollElement.total_answers_for_question }}
        {{ $i18n.t("votes") }}
        {{ element.ended ? " - " + $i18n.t("poll_ended") : "" }}
      </p>
    </div>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    loggedUserID: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      pollLoading: false
    }
  },
  emits: ["answerPoll", "updatePoll"],
  methods: {
    answerPoll(post, question_id, answer_id, answered) {
      if (answered)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "You already have a vote for this question"
            : "Ya tienes un voto para esta pregunta",
          this.$i18n.locale === "en" ? "Error" : "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (post.ended)
        return this.$snotify.error(
          this.$i18n.locale === "en"
            ? "This poll does not allow new votes"
            : "Esta encuesta ya no permite nuevos votos",
          this.$i18n.locale === "en"
            ? "This poll has been closed"
            : "La encuesta fue cerrada",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      if (!this.pollLoading) {
        this.pollLoading = true
        if (!post.answered_poll)
          this.$store
            .dispatch("global/create", {
              payload: {
                id_user: this.loggedUserID,
                id_poll: post.id,
                id_question: question_id,
                id_answer: answer_id
              },
              route: "/post/poll/answer/save",
              module: null
            })
            .then((response) => {
              notify().then(({ notification }) => {
                notification(
                  "poll_answer",
                  response.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              if (response.code === 200) {
                this.$emit("updatePoll", response.data)
              }
              this.pollLoading = false
            })
            .catch((error) => {
              notify().then(({ notification }) => {
                notification(
                  "error",
                  error.code,
                  this.$snotify,
                  this.$i18n.locale
                )
              })
              this.pollLoading = false
            })
      }
    }
  }
}
</script>

<style>

</style>
