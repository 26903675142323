<template>
  <div>
    <img v-if="element.type === 1 && element.url_picture" :alt="element.text"
      class="mx-auto object-cover px-2 py-2 dynamic-aspect-ratio" src="/static/logo_square.png" style="max-height: 80vh"
      :ref="'loading-picture'" />
    <img :alt="element.text" v-if="element.type === 1 && element.url_picture"
      class="hidden mx-auto object-cover px-2 py-2 dynamic-aspect-ratio" @load="onImageLoad" :src="picture(element)"
      style="max-height: 80vh" :ref="element.id" />
    <iframe :id="'video-player-' + element.id" v-if="element.type === 2" width="100%" height="100%"
      style="height: 60vh;" class="mx-auto px-2 py-2 dynamic-aspect-ratio" :src="getYoutubeEmbedLink(element.url_video)"
      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
    <template v-if="element.type === 4">
      <Poll :element="element" :loggedUserID="loggedUserID" @updatePoll="updatePoll" />
    </template>
    <video v-if="element.type === 5" class="mx-auto px-2 py-1" :src="element.imgkit_url_video || element.url_video"
      :style="element.aspect_ratio < 1 ? 'max-height: 80vh' : ''"
      @play="(evt) => handleVideoPlay(element.id, evt.target)" playsinline autoplay controls loop :muted="!atModal"
      controlsList="nodownload"></video>
  </div>
</template>

<script>
import Poll from "@/components/post/Poll.vue"
export default {
  props: {
    element: {
      type: Object,
      required: true
    },
    loggedUserID: {
      type: Number
    },
    sizePx: Number,
    atModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Poll
  },
  emits: ["updatePoll"],
  methods: {
    handleProcessedTextClick(e) {
      if (e.target.matches(".post-hashtag")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/hashtag/all",
            module: null,
            noPaginate: true,
            isMaster: true,
            params: {
              //query: e.target.innerHTML.slice(1),
              name: e.target.innerHTML,
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.$router.push("/post_by_hashtag/" + response.data[0].name.slice(1))
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
      if (e.target.matches(".post-username")) {
        this.$store
          .dispatch("global/getItems", {
            route: "/user/all",
            module: null,
            noPaginate: true,
            params: {
              username: e.target.innerHTML.slice(1),
              limit: 1,
              active: 1
            }
          })
          .then((response) => {
            if (response.data[0]) {
              this.openProfile(response.data[0])
            } else {
              notify().then(({ notification }) => {
                notification("error", 404, this.$snotify, this.$i18n.locale)
              })
            }
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
    picture(element) {
      let url
      if (element.imgkit_url_picture) {
        url = element.imgkit_url_picture
        if (this.sizePx !== -1) url += `?tr=w-${this.sizePx},h-${this.sizePx}`
      } else {
        url = element.url_picture
      }
      return url
    },
    getYoutubeEmbedLink(link) {
      let base_url = "https://www.youtube.com/embed/"
      if (link.includes("youtube")) {
        return (
          base_url +
          link.split("?v=")[1] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else if (link.includes("youtu.be")) {
        return (
          base_url +
          link.split("/")[3] +
          `?feature=oembed&enablejsapi=1&Origin=${window.location.host}`
        )
      } else {
        return ""
      }
    },
    onImageLoad() {
      if (this.$refs["loading-picture"])
        this.$refs["loading-picture"].classList.add("hidden")
      if (this.$refs[this.element.id] && this.$refs[this.element.id].classList)
        this.$refs[this.element.id].classList.remove("hidden")
      if (
        this.$refs[this.element.id][0] &&
        this.$refs[this.element.id][0].classList
      )
        this.$refs[this.element.id][0].classList.remove("hidden")
    },
    updatePoll(e) {
      this.$emit("updatePoll", e)
    },
    handleVideoPlay(postId, htmlElement) {
      if (!htmlElement.classList.contains("viewed")) {
        htmlElement.classList.add("viewed")
        this.$store
          .dispatch("global/update", {
            payload: {
              id: postId,
              new_view: 1
            },
            route: "/post/update",
            module: null
          })
          .catch((error) => {
            notify().then(({ notification }) => {
              notification(
                "error",
                error.code,
                this.$snotify,
                this.$i18n.locale
              )
            })
          })
      }
    },
  }
}
</script>
